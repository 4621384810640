import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Container,
  Button
} from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import {
  fetchServer,
  REQUEST_METHODS,
  setSessionToken
} from '../../../utils/api_utils';
import { useAlert } from 'react-alert';

function LoginForm() {
  const alert = useAlert();

  const useStyles = makeStyles(() => ({
    title: {
      textAlign: 'center',
      fontSize: '80px!important',
      height: '100%'
    }
  }));
  const classes = useStyles();

  async function loginUser() {
    const query_url = `auth/login/`;

    const loginUsername = document.getElementById('authLogin');
    const loginPassword = document.getElementById('authPassword');

    const formData = new FormData();
    const inputValues = [loginUsername.value, loginPassword.value];
    if (inputValues.includes('') || inputValues.includes(null)) {
      alert.error('Required fields are blank');
    } else {
      formData.append('username', loginUsername.value);
      formData.append('password', loginPassword.value);
      await fetchServer(query_url, REQUEST_METHODS.POST, formData)
        .then(async (response) => {
          return { ok: response.ok, data: await response.json() };
        })
        .then(async (responseData) => {
          if (responseData['ok']) {
            setSessionToken(responseData['data']['token']);
          } else {
            alert.error(responseData['data']['msg']);
            loginPassword.value = '';
          }
        })
        .catch((ex) => {
          console.warn(`Exception during loginUser -> ${ex}`);
        });
    }
  }

  return (
    <Container maxWidth="lg" sx={{ height: '100%' }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        sx={{ height: '100%' }}
      >
        <Card>
          <CardHeader title="Authorization" className={classes.title} />
          <Divider />
          <CardContent>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' }
              }}
              noValidate
            >
              <div>
                <TextField required id="authLogin" label="Username" />
                <TextField
                  required
                  id="authPassword"
                  label="Password"
                  type="password"
                />
                <Divider />
                <Button
                  variant="outlined"
                  sx={{ margin: 1 }}
                  size="large"
                  color="primary"
                  onClick={() => loginUser()}
                >
                  Log In
                </Button>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
}

export default LoginForm;
