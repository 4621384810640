/**
 * Universal checker for data emptiness
 * @param value - provided data to check
 */
export const isEmpty = (value) => {
  return (
    // null or undefined
    value == null ||
    // has length and it's zero
    (value.hasOwnProperty('length') && value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  );
};


/**
 * Gets environment variable by fetching it from local storage
 * @param key - environment variable key to fetch
 * @returns {string | null} value of variable if present
 */
export const getEnvironmentVariable = (key) => {
  return localStorage.getItem(key);
}
