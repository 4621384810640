import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import {getEnvironmentVariable} from "./utils/common";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

// Production components go below
// Chatbots

const ChatbotsStatus = Loader(
  lazy(() => import('src/content/pages/Chatbots/Status'))
);

const ChatsList = Loader(lazy(() => import('src/content/pages/Chats/List')));
const Chat = Loader(lazy(() => import('src/content/pages/Chats/Item')));

const PersonasList = Loader(
  lazy(() => import('src/content/pages/Personas/List'))
);
const PersonaForm = Loader(
  lazy(() => import('src/content/pages/Personas/Item'))
);

const LLMSConfigForm = Loader(
  lazy(() => import('src/content/pages/Personas/LLMSConfigForm'))
);


const REACT_APP_STAGE = getEnvironmentVariable("REACT_APP_STAGE");
console.log('setting environment to: ', REACT_APP_STAGE);

let routes = [
    {
      path: '',
      element: <SidebarLayout />,
      children: [
        // TODO: implement "system overview" page for '/' and '/overview'
        {
          path: '/',
          element: <Navigate to="/chatbots/list" replace />
        },
        {
          path: '/overview',
          element: <Navigate to="/chatbots/list" replace />
        },
        {
          path: 'status',
          children: [
            {
              path: '',
              element: <Navigate to="404" replace />
            },
            {
              path: '404',
              element: <Status404 />
            },
            {
              path: '500',
              element: <Status500 />
            },
            {
              path: 'maintenance',
              element: <StatusMaintenance />
            },
            {
              path: 'coming-soon',
              element: <StatusComingSoon />
            }
          ]
        },
        {
          path: '*',
          element: <Status404 />
        }
      ]
    },
    {
      path: '/chatbots',
      element: <SidebarLayout />,
      children: [
        {
          path: 'list',
          element: <ChatbotsStatus />
        }
      ]
    },
    {
      path: '/chats',
      element: <SidebarLayout />,
      children: [
        {
          path: 'list',
          element: <ChatsList />
        },
        {
          path: 'get/:cid',
          element: <Chat />
        }
      ]
    },
    {
      path: '/personas',
      element: <SidebarLayout />,
      children: [
        {
          path: 'list',
          element: <PersonasList />
        },
        {
          path: 'edit/:personaId',
          element: <PersonaForm />
        },
        {
          path: 'add',
          element: <PersonaForm />
        },
        {
          path: 'supported_llms',
          element: <LLMSConfigForm />
        }
      ]
    }
];

export default routes;
