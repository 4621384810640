import { useSelector } from 'react-redux';
import { getSidebarViewState } from '../../../../store';
import { useEffect, useState } from 'react';
import SidebarMenu from './index';
import MySidebarMenu from './my_index';
import {getEnvironmentVariable} from "../../../../utils/common";

function SidebarMenuController() {
  const [devSidebarEnabled, setDevSidebarEnabled] = useState(false);
  const viewMode = useSelector(getSidebarViewState);
  useEffect(() => {
    const shouldShowDevMenu =
      viewMode === 'demo' && getEnvironmentVariable("REACT_APP_STAGE") === 'dev';
    setDevSidebarEnabled(shouldShowDevMenu);
  }, [viewMode]);
  return devSidebarEnabled ? <SidebarMenu /> : <MySidebarMenu />;
}

export default SidebarMenuController;
