import { Tooltip, tooltipClasses, styled, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
        size: 100px;
`
);

const TooltipWrapper = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

const LogoText = styled(Box)(
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(18)};
        font-weight: ${theme.typography.fontWeightBold};
        color: white;
`
);

const LogoTextWrapper = styled(Box)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
`
);

function Logo({ text = '' }) {
  return (
    <TooltipWrapper title="Neon AI" arrow>
      <LogoWrapper to="/overview">
        <img
          src={'/static/images/logo/neon_logo.png'}
          alt="Neon AI Logo"
          style={{ width: '50px' }}
        />
        <LogoTextWrapper>
          <LogoText>{text}</LogoText>
        </LogoTextWrapper>
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
