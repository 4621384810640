import { combineReducers, configureStore, createSlice } from '@reduxjs/toolkit';

const changeSidebarView = createSlice({
  name: 'sidebar_state',
  initialState: { value: 'demo' },
  reducers: {
    changeSidebarState: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const getSidebarViewState = (state) => state.change_sidebar_view.value;

export const { changeSidebarState } = changeSidebarView.actions;

const authorizationSlice = createSlice({
  name: 'authorization',
  initialState: { session: localStorage.getItem('session') },
  reducers: {
    setSession: (state, action) => {
      state.session = action.payload;
    }
  }
});
export const getSession = (state) => state.authorization.session;
export const { setSession } = authorizationSlice.actions;

const submindsStateSlice = createSlice({
  name: 'subminds_state',
  initialState: { subminds: {} },
  reducers: {
    setSubminds: (state, action) => {
      state.subminds = action.payload;
    }
  }
});
export const getSubminds = (state) => state.subminds_state.subminds;
export const { setSubminds } = submindsStateSlice.actions;

const combinedReducers = combineReducers({
  change_sidebar_view: changeSidebarView.reducer,
  authorization: authorizationSlice.reducer,
  subminds_state: submindsStateSlice.reducer
});

export const store = configureStore({ reducer: combinedReducers });
